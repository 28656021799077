import { defineStore } from 'pinia'

export const useRoomsStore = defineStore('roomsStore', {
  state: () => ({
    rooms: [],
    roomsFiltered: []
  }),
  actions: {
    _filterOfWord(array, word) {
      let newArray = []
      array.forEach((item) => {
        if (item.roomName.toLowerCase().includes(word)) {
          newArray.push(item)
        }
      })

      return newArray;
    },

    filtred(word) {
      console.log(word)
      if (word !== '') {
        this.roomsFiltered = [...this._filterOfWord(this.rooms, word.trim().toLowerCase())];
      }
      else {
        this.roomsFiltered = [...this.rooms];
      }
    }
  }
})