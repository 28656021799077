<template>
  <BlockPage v-if="boolBlock"></BlockPage>
  <router-view v-else />

  <!--<RoomPage></RoomPage>-->
  <!--<ProfilePage></ProfilePage>-->
  <!--<FirstPage/>-->
  <!--<AdminPage></AdminPage>-->
</template>

<script>

import { useUserStore } from './state/user';
import { useRoomsStore } from './state/rooms';

import { api } from './api/Api';

import BlockPage from './pages/BlockPage.vue';
import router from './router';

export default {
  name: 'App',
  components: {
    BlockPage,
  },
  created() {
    const WebApp = window.Telegram.WebApp;
    if (WebApp.initData == "") {
      this.boolBlock = true
    }
    else {
      this.boolBlock = false
      console.log('ccskrf' + WebApp.initDataUnsafe.user.photo_url) //, WebApp.initDataUnsafe.chat.ID
      let fotoLink = '';
      if(String(WebApp.initDataUnsafe.user.photo_url).length > 20) {
        fotoLink = WebApp.initDataUnsafe.user.photo_url;
      }
      else {
        fotoLink = ''
      }

      api.getUser(WebApp.initDataUnsafe.user.username, WebApp.initDataUnsafe.user.first_name, fotoLink)
        .then((res) => {
          this.userStore.$state = {
            room: res.room,
            teleuser: res.teleUser,
            username: res.userName,
            role: res.role,
            fotoLink: res.urlFoto
          }
          if (res.room == '') { //1111111111111111111111111111111111111111111111111111111111111111
            api.getRooms()
              .then((res) => {
                console.log(res)
                this.useRooms.$state = {
                  rooms: [...res.rooms],
                  roomsFiltered: [...res.rooms],
                }
              })
              .catch((err) => {
                console.log(err);
              })
          }
          else {
            router.push('/room')
            api.getRooms()
              .then((res) => {
                console.log(res)
                this.useRooms.$state = {
                  rooms: [...res.rooms],
                  roomsFiltered: [...res.rooms],
                }
              })
              .catch((err) => {
                console.log(err);
              })
          }
        })
        .catch((err) => {
          console.log(err);
        })

    }
  },
  data() {
    let boolBlock = true;
    const userStore = useUserStore();
    const useRooms = useRoomsStore();
    return {
      boolBlock, userStore, useRooms
    }
  }

}
</script>

<style>
body {
  height: 100vh;
  margin: 0;
}

#app {
  position: relative;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  height: 100vh;
  width: 100vw;
}
</style>
