class Api {
  constructor({ baseUrl, headers }) {
    this._baseUrl = baseUrl;
    this._headers = headers;
  }

  _checkResponse(res) {
    if (res.ok) {
      return res.json();
    }
    return Promise.reject(`Ошибка: ${res.status}`);
  }
//


  getUser(teleUser, userName, fotoLink) {
    console.log(fotoLink)
    /*const searchParams = new URLSearchParams({
      'teleuser': teleUser,
      'username': userName,
});*/
    console.log(fotoLink)
    return fetch(`${this._baseUrl}/user/`, {
      headers: this._headers,
      method: "POST",
      body: JSON.stringify({
        'teleuser': teleUser,
        'username': userName,
        'fotolink': String(fotoLink)
      }),
    }).then(this._checkResponse);
  }

  deleteUser(userName) {
    return fetch(`${this._baseUrl}/room-user-delete/`, {
      method: "POST",
      body: JSON.stringify({
        username: userName
      }),
      headers: this._headers,
    }).then(this._checkResponse);
  }

  getUsers(roomName) {
    const searchParams = new URLSearchParams({
      'roomName': roomName,
    });
    return fetch(`${this._baseUrl}/room-users/?${searchParams.toString()}`, {
      headers: this._headers,
    }).then(this._checkResponse);
  }

  getRooms() {
    return fetch(`${this._baseUrl}/rooms/`, {
      headers: this._headers,
    }).then(this._checkResponse);
  }

  getADDUser(roomName) {
    return fetch(`${this._baseUrl}/getAdd/`, {
      method: "POST",
      headers: this._headers,
      body: JSON.stringify({
        room: roomName, 

      }),
    }).then(this._checkResponse);
  }

  addRoom(roomName, userName) {
    return fetch(`${this._baseUrl}/rooms/`, {
      method: "POST",
      headers: this._headers,
      body: JSON.stringify({
        roomname: roomName, 
        username: userName
      }),
    }).then(this._checkResponse);
  }

  ttdel(roomName, userName) {
    return fetch(`${this._baseUrl}/ttdel/`, {
      method: "POST",
      headers: this._headers,
      body: JSON.stringify({
        room: roomName, 
        user: userName
      }),
    }).then(this._checkResponse);
  }

  ttAdd(roomName, userName) {
    return fetch(`${this._baseUrl}/ttAdd/`, {
      method: "POST",
      headers: this._headers,
      body: JSON.stringify({
        room: roomName, 
        user: userName
      }),
    }).then(this._checkResponse);
  }



  deleteRoom(roomName) {
    return fetch(`${this._baseUrl}/delete/`, {
      method: "POST",
      headers: this._headers,
      body: JSON.stringify({
        room: roomName, 
      }),
    }).then(this._checkResponse);
  }

  addInRoom(roomID, userName) {
    return fetch(`${this._baseUrl}/room-add/`, {
      method: "POST",
      headers: this._headers,
      body: JSON.stringify({
        roomID: roomID, 
        username: userName
      }),
    }).then(this._checkResponse);
  }

//

  patchUser(first, second, middle, username) {
    return fetch(`${this._baseUrl}/profile/${username}`, {
      method: "PATCH",
      headers: this._headers,
      body: JSON.stringify({
        firstName: first,
        secondName: second,
        middleName: middle,
      }),
    }).then(this._checkResponse);
  }

  putUserImage(username, image) {
    let formData = new FormData();
    formData.append('file', image)
    return fetch(`${this._baseUrl}/profile/${username}/image`, {
      method: "POST",
      body: formData,
    }).then(this._checkResponse);
  }

  postScreenshotImage(username, image, day) {
    let formData = new FormData();
    formData.append('file', image)
    formData.set('date', day)
    return fetch(`${this._baseUrl}/screenshots/${username}`, {
      method: "POST",
      body: formData,
    }).then(this._checkResponse);
  }

  getPersonalSteps(username) {
    return fetch(`${this._baseUrl}/step/${username}`,
      { headers: this._headers })
      .then(this._checkResponse);
  }

  addStep(username, date) {
    return fetch(`${this._baseUrl}/step/${username}`, {
      method: "POST",
      headers: this._headers,
      body: JSON.stringify({
        date: date,
      }),
    }).then(this._checkResponse);
  }

  addPersonalStep(username, date, step) {
    return fetch(`${this._baseUrl}/step/${username}`, {
      method: "PATCH",
      headers: this._headers,
      body: JSON.stringify({
        step: step,
        date: date,
      }),
    }).then(this._checkResponse);
  }

  getTeamSteps(team) {
    return fetch(`${this._baseUrl}/team/${team}`, {
      headers: this._headers,
    }).then(this._checkResponse);
  }

  getAllSteps() {
    return fetch(`${this._baseUrl}/all-step`, {
      headers: this._headers,
    }).then(this._checkResponse);
  }

  getSetting() {
    return fetch(`${this._baseUrl}/setting`, {
      headers: this._headers,
    }).then(this._checkResponse);
  }

  getPeopleTop() {
    return fetch(`${this._baseUrl}/people-top`, {
      headers: this._headers,
    }).then(this._checkResponse);
  }

  getPeopleTopAllStep() {
    return fetch(`${this._baseUrl}/people-top-allstep`, {
      headers: this._headers,
    }).then(this._checkResponse);
  }

  getTeamList() {
    return fetch(`${this._baseUrl}/team-top`, {
      headers: this._headers,
    }).then(this._checkResponse);
  }

  getTeamDaySort(team) {
    return fetch(`${this._baseUrl}/team-sort/${team}`, {
      headers: this._headers,
    }).then(this._checkResponse);
  }

  postNews(text, author) {
    return fetch(`${this._baseUrl}/news`, {
      headers: this._headers,
      method: "POST",
      body: JSON.stringify({
        text: text,
        author: author,
      }),
    }).then(this._checkResponse);
  }

  getNews() {
    return fetch(`${this._baseUrl}/news`, {
      headers: this._headers,
    }).then(this._checkResponse);
  }

  saveFile(url, filename) {
    const a = document.createElement("a");
    a.href = url;
    a.download = filename;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }

  saveTemplate() {
    return fetch(`${this._baseUrl}/template`, {
      headers: {
        Accept:
          "application/json, text/plain,application/zip, image/png, image/jpeg, image/*",
      },
    }).then(res => res.blob())
      .then(blob => {
        const file = URL.createObjectURL(blob);
        this.saveFile(file, 'Шаблон.xlsx');
        URL.revokeObjectURL(file);
      });
  }

  postTemplate(template) {
    let formData = new FormData();
    formData.append('file', template)
    return fetch(`${this._baseUrl}/registration-template`, {
      method: "POST",
      body: formData,
    }).then(this._checkResponse);
  }

  getFile() {
    return fetch(`${this._baseUrl}/statistics`, {
      headers: {
        Accept:
          "application/json, text/plain,application/zip, image/png, image/jpeg, image/*",
      },
    }).then(res => res.blob())
      .then(blob => {
        const file = URL.createObjectURL(blob);
        this.saveFile(file, 'Свод.xlsx');
        URL.revokeObjectURL(file);
      });
  }

  authorization(username, password) {
    return fetch(`${this._baseUrl}/login`, {
      headers: this._headers,
      method: "POST",
      body: JSON.stringify({
        password: password,
        username: username,
      }),
    }).then(this._checkResponse);
  }
}

/*const api = new Api({
  baseUrl: '//localhost:5000/_api',
  headers: {
    "Content-Type": "application/json",
  },
});*/

const api = new Api({
  baseUrl: 'https://secret-santa-bot.ru/_api',/// https://secret-santa-bot.ru/_api http://127.0.0.1:5000/_api
  headers: {
    "Content-Type": "application/json",
  },
});

export { api };
