<template>
  <div class="block-page">
    <h1>Ошибка</h1>
    <p>Этот сайт создан в формате</p>
    <h3>Telegram Mini Apps</h3>
    <p>войдите, пожалуйста, через телеграмм.</p>
  </div>
</template>

<script>

export default {
  name: 'BlockPage',
}
</script>

<style scoped>
.block-page {
  width: 100%;
  height: 100%;
}
</style>