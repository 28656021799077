import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    name: 'firstPage',
    component: () => import('../pages/FirstPage.vue')
  },
  {
    path: '/profile',
    name: 'profilePage',
    component: () => import('../pages/ProfilePage.vue'),
  },
  {
    path: '/room',
    name: 'roomPage',
    component: () => import('../pages/RoomPage.vue'),
  },
  {
    path: '/roomAdmin',
    name: 'roomAdmin',
    component: () => import('../pages/AdminPage.vue'),
  },
  {
    path: '/:pathMatch(.*)*',
    redirect: '/',
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
