import { createApp } from 'vue'
import { createPinia } from 'pinia'
const pinia = createPinia()
import App from './App.vue'
import router from './router'

import '@/шрифты/fonts.css'

const app = createApp(App)

app.use(pinia);
app.use(router);

app.mount('#app')