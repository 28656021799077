import { defineStore } from 'pinia'

export const useUserStore = defineStore('userStore', {
  state: () => ({
    room: '',
    teleuser: '',
    username: '',
    role: '',
    fotoLink: ''
  }),
})